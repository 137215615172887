import { render, staticRenderFns } from "./MaterialAvailability.vue?vue&type=template&id=683957ac&scoped=true&"
import script from "./MaterialAvailability.vue?vue&type=script&lang=js&"
export * from "./MaterialAvailability.vue?vue&type=script&lang=js&"
import style0 from "./MaterialAvailability.vue?vue&type=style&index=0&id=683957ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683957ac",
  null
  
)

export default component.exports